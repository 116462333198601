.side-nav .logo-wrapper a {
    height: auto!important;
}
.side-nav .logo-wrapper {
    height: auto!important;
}
.right-button {
    float: right!important;
}
span.caret{
    top: 1.8rem!important;
}
.select-wrapper input.select-dropdown {
    color: #00c851!important;
}
label {
    display: inline-block;
     margin-bottom: 0!important;
    font-size: 20px!important;
    color: burlywood!important;
}

.question_content .question_content__title {
    display: block;
    text-align: left;
}
.question_content .question_content__body .md-form {
    margin: 0;
}
.question_content .question_content__body .md-form input[type='text'] {
    padding: 0;
}
.question_content .question_content__body .radio_group {
    display: flex;
}
.question_content .question_content__body .radio_group .radio_group__item {
    margin-right: 25px;
}
.question_content .question_content__body .radio_group .radio_group__item:last-child {
    margin-right: 0px;
}
.question_content .question_content__body .radio_group .radio_group__item [type="radio"]:not(:checked),
.question_content .question_content__body .radio_group .radio_group__item [type="radio"]:checked {
    position: static;
    opacity: 1;
    pointer-events: auto;
}
.question_content .question_content__body .radio_group .radio_group__item label {
    margin-left: 5px;
}
.question_select_custom {
    margin-bottom: 15px;
    text-align: left;
}

.body_image_preview img {
    max-width: 100%;
}